module services {
    export module master {
        export class customsRateOfExchangeService implements interfaces.master.ICustomsRateOfExchangeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getList(): ng.resource.IResourceClass<interfaces.master.ICustomsRateOfExchange> {
                return this.$resource<interfaces.master.ICustomsRateOfExchange>(this.ENV.DSP_URL + "CustomsRateOfExchange/GetList", {
                    selectedDate: "@selectedDate",
                    countryId: "@countryId",
                    numberRecords: "@numberRecords",
                    pageNumber: "@pageNumber",
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }
        }
    }
    angular.module("app").service("customsRateOfExchangeService", services.master.customsRateOfExchangeService);
}